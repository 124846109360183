<template>
  <div id="investment" class="container">
    <h1 class="d-none d-xl-block">
      {{ getInvestment.title }}
    </h1>
    <h2 class="d-xl-none d-sm-block">
      {{ getInvestment.title }}
    </h2>
    <div
      id="investment-data"
      class="row mt-5"
    >
      <div
        id="top-divider"
        class="col-12 mb-5"
      >
        <hr>
        <p class="author pl-0">{{getInvestment.author}}</p>
        <p class="time-stamp">{{getInvestment.createdAt}}</p>
      </div>
      <div
        v-for="investment in getInvestment.content"
        :key="investment.id"
        class="col-12"
      >
        <structured-text
          v-if="isText(investment)"
          :data="investment.text.value"
        />
        <b-img
          v-if="isImage(investment)"
          class="mb-3"
          :src="investment.image.url"
          :alt="investment.image.url"
          fluid-grow
        />
      </div>
    </div>
    <div
      id="bottom-divider"
      class="row mt-5"
    >
      <div class="col-12">
        <hr>
        <p class="time-stamp pl-0">{{getInvestment.createdAt}}</p>
        <p class="author">{{getInvestment.author}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {StructuredText} from 'vue-datocms'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'Investment',
  components: {
    StructuredText
  },
  data() {
    return {
      content: null
    }
  },
  methods: {
    ...mapActions(['queryInvestment']),
    isText(check) {
      // eslint-disable-next-line no-prototype-builtins
      return check.hasOwnProperty('text')
    },
    isImage(check) {
      // eslint-disable-next-line no-prototype-builtins
      return check.hasOwnProperty('image')
    }
  },
  computed: {
    ...mapGetters(['getInvestment'])
  },
  mounted() {
    this.queryInvestment(this.$route.params.investment)
  }
}

</script>

<style scoped lang="scss">
@import '../../styles/_variables.scss';

#investment {
  margin-top: 83px;
  line-height: 2rem;
  font-size: 1.3rem;
  text-align: justify;

  a {
    color: $light-pink;

    &:hover {
      color: white;
      background-color: $light-pink;
      text-decoration: none;
    }

    &:visited {
      color: $light-pink;
    }
  }

  h1 {
    font-size: 5rem;
    line-height: 5rem;
  }
  h2 {
    font-size: 2rem;
    line-height: 2rem;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    font-weight: normal;

    span {
      color: $regular-pink;
    }
  }

  #investment-data {
    h1 {
      font-size: 2.5rem;
    }
    h2 {
      font-size: 2.2rem;
    }
    h3 {
      font-size: 2rem;
    }
    h4 {
      font-size: 1.9rem;
    }
    h5 {
      font-size: 1.8rem;
    }
    h6 {
      font-size: 1.6rem;
    }
  }

  hr {
    background-color: $regular-pink;
  }

  .time-stamp, .author {
    font-size: 1rem;
    width: auto;
    position: absolute;
    top: 0;
    background-color: $dark-grey;
    padding-left: 1rem;
    padding-right: 1rem;
    font-family: 'Anonymous Pro', monospace;
  }

  #top-divider {
    .time-stamp {
      right: 0;
    }
  }

  #bottom-divider {
    .author {
      right: 0;
    }
  }

  mark {
    background-color: $dark-grey;
    color: white;
    border-bottom: 1px solid $light-pink;

    &:before {
      content: ' ';
      background-color: red;

    }
  }

  pre {
    border-left: 2px solid $regular-pink;
    padding-left: 2rem;
    color: $regular-pink;
  }

  blockquote {
    font-family: 'Shippori Mincho B1', serif;
    border-left: 2px solid $regular-pink;
    padding-left: 2rem;

    p {
      font-size: 2rem;
      font-style: italic;
    }

    footer {
      &:before {
        content: '— ';
      }
    }
  }
}
</style>